// nuxt-ui/Shared/composables/players.ts

import type {
  Player,
  PlayerRanking,
  PlayerSeasons,
  SeasonPlayerInfo,
  TurnMvpPlayer,
} from "~/src/player/domain/Player";
import type { PlayerDTO, PlayerRankingsDTO } from "~/src/player/infrastrructure/DTO/PlayerDTO";
import type { Team } from "~/src/team/domain/models/Team";
import type { Match } from "~/src/match/domain/models/Match";
import type { Season } from "~/src/season-cluster/domain/models/01.Season";
import { sortItemsByParam } from "~/src/Shared/utils";

interface UsePlayers {
  getPlayerSeasonStats: (
    seasonId: number | undefined,
    playerId: number | undefined
  ) => Promise<PlayerRanking[] | undefined>;
  getPlayerCurrentTeam: (playerId: number) => Promise<Team | undefined>;
  useFetchParamsPlayerSeasons: (playerId: number) => string;
  findLastSeasonPlayedByPlayer: (seasons: SeasonPlayerInfo[]) => Season;
  getPlayer: (playerId: number) => Promise<Player | undefined>;
  getPlayerSeasonMatches: (seasonId: number, playerId: number) => Promise<Match[] | undefined>;
  getSeasonTurnMvps: (seasonId: number) => Promise<TurnMvpPlayer[] | undefined>;
  getLastTurnMvp: (season: Season) => Promise<TurnMvpPlayer | undefined>;
}

export const usePlayers = (): UsePlayers => {
  return {
    getPlayer,
    getPlayerCurrentTeam,
    getPlayerSeasonMatches,
    getPlayerSeasonStats,
    useFetchParamsPlayerSeasons,
    findLastSeasonPlayedByPlayer,
    getSeasonTurnMvps,
    getLastTurnMvp,
  };

  async function getPlayer(playerId: number): Promise<Player | undefined> {
    try {
      const path = `/api/v1/competition/players/${playerId}`;
      const player = await $fetch<PlayerDTO>(path);

      if (player) {
        return player;
      } else {
        console.error("Fetched player is null");
      }
    } catch (e) {
      console.error("Error fetching player", e);
    }
  }

  async function getPlayerCurrentTeam(playerId: number): Promise<Team | undefined> {
    try {
      const player = await getPlayer(playerId);
      if (player?.currentTeam) {
        return player.currentTeam;
      } else {
        console.error("Fetched player current team is null");
      }
    } catch (e) {
      console.error("Error fetching player current team", e);
    }
  }

  // async function getPlayerMarketValue(playerId: number): Promise<number | undefined> {
  //   try {
  //     const player = await getPlayer(playerId);
  //     const marketValue = player?.marketValue;
  //     if (marketValue) {
  //       return marketValue;
  //     } else {
  //       console.error("Fetched market value is null for player: ", playerId);
  //     }
  //   } catch (e) {
  //     console.error("Error fetching player's market value", e);
  //   }
  // }

  // async function getPlayerSeasons(playerId: number): Promise<PlayerSeasons | undefined> {
  //   try {
  //     const path = `/api/v1/competition/players/${playerId}/seasons`;
  //     const playerSeasons = await $fetch<PlayerSeasons>(path);
  //
  //     if (playerSeasons) {
  //       return playerSeasons;
  //     } else {
  //       console.error("Fetched player seasons are null");
  //     }
  //   } catch (e) {
  //     console.error("Error fetching player", e);
  //   }
  // }

  function useFetchParamsPlayerSeasons(playerId: number): string {
    return `/api/v1/competition/players/${playerId}/seasons`;
  }

  function findLastSeasonPlayedByPlayer(seasons: SeasonPlayerInfo[]): Season {
    return seasons.sort((a, b) => {
      return a.season.finish && b.season.finish && a.season.finish > b.season.finish
        ? 1
        : a.season.finish && b.season.finish && a.season.finish < b.season.finish
          ? -1
          : 0;
    })[seasons.length - 1].season;
  }

  async function getPlayerSeasonMatches(seasonId: number, playerId: number): Promise<Match[] | undefined> {
    try {
      const path = `/api/v1/competition/players/${playerId}/season-data/${seasonId}/matches`;
      const matches = await $fetch<Match[]>(path);

      if (matches) {
        //Show only matches 24h before the match start
        return matches.filter(match => {
          if (!match.date) return false;
          const matchDate = new Date(match.date);
          if (matchDate.toLocaleString() === "Invalid Date") return false;

          const hoursToShowMatch = 24;
          const nowInHs = new Date().getTime() / 1000 / 60 / 60;
          const matchDateInHs = matchDate.getTime() / 1000 / 60 / 60;

          //If the match starts in ${hoursToShowMatch} or it's past
          return matchDateInHs - nowInHs < hoursToShowMatch;
        });
      } else {
        console.error("Fetched player season matches are null");
      }
    } catch (e) {
      console.error("Error fetching player matches of the season", e);
    }
  }

  async function getPlayerSeasonStats(
    seasonId: number | undefined,
    playerId: number | undefined
  ): Promise<PlayerRanking[] | undefined> {
    try {
      if (typeof seasonId === "number" && typeof playerId === "number") {
        const path = `/api/v1/competition/players/${playerId}/season-data/${seasonId}/stats`;
        const player = await $fetch<PlayerRankingsDTO>(path);
        const rankings = player?.rankings;

        if (rankings) {
          return rankings;
        } else {
          console.error("Fetched player season stats is null");
        }
      } else {
        console.error("Missing playerId or seasonId params.");
      }
    } catch (e) {
      console.error("Error on fetch player season stats", e);
    }
  }

  // async function getPlayerSeasonInfoData(
  //   playerId: number,
  //   seasonId: number
  // ): Promise<SeasonPlayerInfo | undefined> {
  //   try {
  //     const playerSeasons = await getPlayerSeasons(playerId);
  //
  //     if (playerSeasons) {
  //       const seasonInfo = playerSeasons.seasons.find(s => s.season.id === seasonId);
  //
  //       if (seasonInfo) {
  //         return seasonInfo;
  //       } else {
  //         console.error("Fetched player info data is null");
  //       }
  //     }
  //   } catch (e) {
  //     console.error("Error on fetch player", e);
  //   }
  // }

  //TODO: move to /seasons?
  async function getSeasonTurnMvps(seasonId: number): Promise<TurnMvpPlayer[] | undefined> {
    try {
      const useSeasons = await import("~/nuxt-ui/Shared/composables/season/seasons");
      const season = await useSeasons.useSeasons().getSeason(seasonId);

      let response = [];

      if (season) {
        for (const phase of season.phases) {
          for (const group of phase.groups) {
            const orderedTurns = sortItemsByParam(group.turns, "start");

            for (const turn of orderedTurns) {
              if (turn.mvpPlayerId !== null && turn.mvpPlayerId !== undefined) {
                try {
                  const mvpPlayer = await $fetch<PlayerSeasons>(
                    `/api/v1/competition/players/${turn.mvpPlayerId}/seasons`
                  );
                  response.push({
                    turnName: turn.mvpName,
                    player: mvpPlayer,
                    playerSeasonInfo: mvpPlayer?.seasons.find(s => s.season.id === season.id),
                  });
                } catch (error) {
                  console.error(`Error fetching player for turn ${turn.name}:`, error);
                }
              }
            }
          }
        }
      }

      if (response) {
        return response;
      } else {
        console.error("Fetched turn mvps are null");
      }
    } catch (e) {
      console.error("Error fetching player", e);
    }
  }

  //TODO: move to /seasons?
  async function getLastTurnMvp(season: Season): Promise<TurnMvpPlayer | undefined> {
    try {
      if (!season) {
        console.error("Missing season.");
        return;
      }

      let lastMvp;

      if (season) {
        for (const phase of season.phases) {
          for (const group of phase.groups) {
            const orderedTurns = sortItemsByParam(group.turns, "start");
            for (const turn of orderedTurns) {
              if (turn.mvpPlayerId !== null) {
                try {
                  lastMvp = {
                    turnName: turn.mvpName,
                    player: turn.mvpPlayerId,
                  };
                } catch (error) {
                  console.error(`Error fetching player for turn ${turn.name}:`, error);
                }
              }
            }
          }
        }
      }

      if (lastMvp?.player) {
        const mvpPlayer = await $fetch<PlayerSeasons>(
          `/api/v1/competition/players/${lastMvp.player}/seasons`
        );

        return {
          turnName: lastMvp.turnName,
          player: mvpPlayer,
          playerSeasonInfo: mvpPlayer?.seasons.find(s => s.season.id === season.id),
        };
      } else {
        console.error("Fetched turn mvps are null");
      }
    } catch (e) {
      console.error("Error fetching player", e);
    }
  }
};
